import React, { useRef } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import './style.scss';

const InputWithStartIcon = React.forwardRef(
  (
    {
      startTextOrIcon,
      endTextOrIcon,
      name,
      id,
      label,
      placeholder,
      handleOnChange,
      value,
      disabled,
      children,
      onFocus = null,
      readOnly = false,
      onClick = null
    },
    ref = null
  ) => {
    return (
      <Form.Group
        controlId={id || name}
        className="normal-form-group mb-3 input-group-container"
      >
        <InputGroup>
          {startTextOrIcon && (
            <InputGroup.Text className="input-start" id="inputGroupPrepend">
              {startTextOrIcon}
            </InputGroup.Text>
          )}
          <Form.Control
            ref={ref}
            label={label}
            name={name}
            className="input-field"
            onChange={e => handleOnChange(e)}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onFocus={onFocus}
            readOnly={readOnly}
            onClick={onClick}
          />
          {endTextOrIcon && (
            <InputGroup.Text className="input-end">
              {endTextOrIcon}
            </InputGroup.Text>
          )}
          {children}
        </InputGroup>
      </Form.Group>
    );
  }
);

export default InputWithStartIcon;
